<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Model Güncelle
          </h6></b-col>
        </b-row>
        <b-form-group label="Başlık">
          <b-form-input
            v-model="modelData.title"
            placeholder="Başlık"
          />
        </b-form-group>
        <b-form-group label="Marka">
          <v-select
            v-model="modelData.id_com_brand"
            :options="brands"
            label="name"
            :reduce="option => option.id"
            placeholder="Seçiniz"
          />
        </b-form-group>
        <b-form-group label="Takas Araç Modeli">
          <v-select
            v-model="modelData.id_com_swap_model"
            :options="swapModels"
            label="title"
            :reduce="option => option.id"
            placeholder="Seçiniz"
          >
            <template v-slot:option="option">
              <div v-if="option.title">
                {{ option.title }}
                <div>
                  <small class="text-warning">{{ option.brand }}</small>
                </div>
              </div>
              <div v-else>
                {{ option.title }}
              </div>
            </template>
            <template v-slot:selected-option="option">
              <div v-if="option.title">
                {{ option.title }}
                <small class="text-warning">{{ option.brand }}</small>
              </div>
              <div v-else>
                {{ option.title }}
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'ModelsEdit',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
    }
  },
  computed: {
    modelData() {
      return this.$store.getters['models/getModel']
    },
    saveStatus() {
      return this.$store.getters['models/getModelSaveStatus']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    swapModels() {
      return this.$store.getters['swapmodels/getSwapmodels']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getModel()
    this.getBrands()
    this.getSwapModels()
  },
  methods: {
    getModel() {
      this.$store.dispatch('models/modelView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('models/modelSave', this.modelData)
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        where: {
          'com_brand.salestatus': '1',
        },
      })
    },
    getSwapModels() {
      this.$store.dispatch('swapmodels/swapmodelsList', {
        where: {
          'com_swap_model.hidden': null,
        },
      })
    },
  },
}
</script>
